// utils/adminUrlGenerator.js

// Definição de mapeamento de rotas
const adminRoutes = {
    //academy
    institution: () => `/academy/institutions`,

    //agency
    mundo_indie_request: () => `/agency/mundo-indie/projects/list`,
    //community
    rating_commentary: () => `/community/ratings`,
    content_commentary: () => `/community/comments`,
    report: () => `/community/reports/list`,
    //club
    //exchange
    //jobs
    vacancy: (id) => `/vacancies/edit/${id}`,
    mentorship_recruitment_request: () => `/requests/list`,
    //network
    invoice: () => `/network/financial/invoices/list`,
    contact_message: () => `/mundo-gamer-platforms/messages`,
    career_application: () => `/network/careers/applications/list`,
    skill: () => `/network/careers/skills/list`,
    company: (id) => `/companies/edit/${id}`,
    partner_message: (id) => `/network/partners/edit/${id}`,
    //shop
    //token
    //tv
    streamer_application: () => `/tv/streamers/applications/list`,
    streamer: () => `/tv/streamers/list`,
    
    // Adicione mais tipos de objetos conforme necessário
};

/**
 * Gera a rota com base no tipo de objeto e ID.
 * @param {string} object - Tipo do objeto (e.g., 'vacancy', 'user').
 * @param {number|string} id - ID do objeto.
 * @returns {string|null} - A rota gerada ou `null` se o tipo for desconhecido.
 */
export function generateAdminRoute(object, id) {
    const route = adminRoutes[object];
    if (!route) {
        console.warn(`Tipo de objeto desconhecido: ${object}`);
        return null;
    }
    return route(id);
}
