import { canNavigate } from '@/services/auth.service'
import Vue from 'vue'
import VueRouter, { Route } from 'vue-router'
import academy from './academy'
import agency from './agency'
import ai from './ai'
import club from './club'
import community from './community'
import dashboard from './dashboard'
import jobs from './jobs/'
import network from './network'
import services from './services'
import shop from './shop'
import token from './token/'
import tv from './tv/'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
// push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
	return originalPush.call(this, location).catch(err => err)
}

VueRouter.prototype.replace = function push(location, onResolve, onReject) {
	if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
	return originalReplace.call(this, location).catch(err => err)
}

const routes = [
	{
		path: '/',
		redirect: to => {
			// const userData = JSON.parse(localStorage.getItem('userData'))
			// const userRole = userData && userData.role ? userData.role : null

			// if (userRole === 'admin')
			return {
				name: 'network-dashboard',
			}

			// return {
			// 	name: 'auth-login',
			// 	query: to.query,
			// }
		},
	},
	{
		path: '/error-404',
		name: 'error-404',
		component: () => import('@/views/Error404.vue'),
		meta: {
			layout: 'blank',
			resource: 'Public',
		},
	},
	{
		path: '/error-403',
		name: 'error-403',
		component: () => import('@/views/Error403.vue'),
		meta: {
			layout: 'blank',
			resource: 'Public',
		},
	},
	{
		path: '/login',
		name: 'auth-login',
		component: () => import('@/views/Login.vue'),
		meta: {
			layout: 'blank',
			resource: 'Public',
			redirectIfLoggedIn: true,
		},
	},
	{
		path: process.env.VUE_APP_ACCOUNTS_URL + '/logout',
		name: 'auth-logout',
        beforeEnter() {
            window.location.href = process.env.VUE_APP_ACCOUNTS_URL + '/logout' // Redireciona para link externo
        }
	},
	{
		path: '/register',
		name: 'auth-register',
		component: () => import('@/views/Register.vue'),
		meta: {
			layout: 'blank',
			resource: 'Public',
			redirectIfLoggedIn: true,
		},
	},
	{
		path: '/forgot-password',
		name: 'auth-forgot-password',
		component: () => import('@/views/ForgotPassword.vue'),
		meta: {
			layout: 'blank',
			resource: 'Public',
			redirectIfLoggedIn: true,
		},
	},
	// {
	// 	path: '/mundo-gamer-messages',
	// 	name: 'mg-platforms-messages',
	// 	component: () => import('@/views/messages/PlatformsMessages.vue'),
	// 	props: true,
	// 	meta: {
	// 		layout: 'content',
	// 	},
	// },
	{
		path: '/mundo-gamer-platforms/messages',
		name: 'mg-platforms-messages',
		component: () => import('@/views/MGPlatforms.vue'),
		props: true,
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/mundo-gamer-messages/:context',
		name: 'mundo-gamer-messages',
		component: () => import('@/views/messages/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/mundo-gamer-platforms/newsletters',
		name: 'mg-platforms-newsletters',
		component: () => import('@/views/MGPlatforms.vue'),
		props: true,
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/mundo-gamer-newsletters/:context',
		name: 'mundo-gamer-newsletters',
		component: () => import('@/views/newsletters/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/mundo-gamer-platforms/notifications',
		name: 'mg-platforms-notifications',
		component: () => import('@/views/MGPlatforms.vue'),
		props: true,
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/mundo-gamer-notifications/:context',
		name: 'mundo-gamer-notifications',
		component: () => import('@/views/notifications/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/mundo-gamer-platforms/faqs',
		name: 'mg-platforms-faqs',
		component: () => import('@/views/MGPlatforms.vue'),
		props: true,
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/mundo-gamer-faqs/:context',
		name: 'mundo-gamer-faqs',
		component: () => import('@/views/network/network-systems/faqs/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/mundo-gamer-platforms/roles',
		name: 'mg-platforms-roles',
		component: () => import('@/views/MGPlatforms.vue'),
		props: true,
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/mundo-gamer-roles/:context',
		name: 'mundo-gamer-roles',
		component: () => import('@/views/network/network-systems/acl/roles/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/mundo-gamer-platforms/permissions',
		name: 'mg-platforms-permissions',
		component: () => import('@/views/MGPlatforms.vue'),
		props: true,
		meta: {
			layout: 'content',
		},
	},
	{
		path: '/mundo-gamer-permissions/:context',
		name: 'mundo-gamer-permissions',
		component: () => import('@/views/network/network-systems/acl/permissions/list.vue'),
		meta: {
			layout: 'content',
		},
	},
	...dashboard,
	...services,
	...network,
	...academy,
	...ai,
	...community,
	...agency,
	...jobs,
	...shop,
	...club,
	...token,
	...tv,
	{
		path: '*',
		redirect: 'error-404',
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return {
			x: 0,
			y: 0,
		}
	},
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
/**
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
router.beforeEach((to, from, next) => {
	if (canNavigate(to.meta.roles, router.app)) {
		next()
	} else {
		next({
			name: 'error-403',
		})
	}

	// const isAuthenticated = localStorage.getItem('accessToken')

	// if (to.meta.resource === 'Public' && !isAuthenticated) next()
	// else if (isAuthenticated) next()
	// else next({ name: 'auth-login' })

	// const userData = localStorage.getItem('userData')

	// const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage
	// 	.getItem('userAbility')

	// console.log(isLoggedIn)

	// if (!canNavigate(to)) {
	// 	// Redirect to login if not logged in
	// 	if (!isLoggedIn) return next({
	// 		name: 'auth-login',
	// 		query: {
	// 			marketplace: to.query.marketplace
	// 		}
	// 	})

	// 	// If logged in => not authorized
	// 	return next({
	// 		name: 'misc-not-authorized'
	// 	})

	// 	// return next({ name: 'misc-not-authorized' })
	// }

	// // Redirect if logged in
	// if (to.meta.redirectIfLoggedIn && isLoggedIn) {
	// 	next('/')
	// }

	// return next()
})

export default router
