import { serialize } from '@/@core/libs/serialize/util'
import Http from './http'

// // INVOICES
// // // LIST ALL INVOICES
export const invoicesList = (params = { page: 1, filter: '', language: '' }) =>
	Http.get(`/invoices${serialize(params)}`)
// // // GET INVOICES BY ID
export const invoicesByID = (params = '') => Http.get(`/invoices/${params}`)
// // // POST NEW INVOICES
export const newInvoices = (params = '') => Http.post('/invoices', params)
// // // UPDATE INVOICES
export const updateInvoices = (params = '') => Http.put(`/invoices/${params.id}`, params)
// // // DELETE INVOICES
export const deleteInvoicesByID = (params = '') => Http.delete(`/invoices/${params.id}`)
// // // STATS INVOICES
export const invoicesStats = (params = { filter: '', language: '' }) =>
	Http.get(`/invoices/status-counts${serialize(params)}`)
